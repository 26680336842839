import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import { Badge, ContactMail, Flag, FormatQuote, History, Home, HowToReg, List, Password, PrivacyTip, Report, SellOutlined, TocOutlined } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminSideBar = () => {
    const [open, setOpen] = useState(null);
    const location = useLocation();
    const { user } = useSelector(state => state.auth);
    const items = [
        {
            name: 'Categories',
            value: '/category',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"><circle cx="17" cy="7" r="3" /><circle cx="7" cy="17" r="3" /><path d="M14 14h6v5a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zM4 4h6v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1z" /></g></svg>,
            subitem: [],
            key: 'p_categories'
        },
        {
            name: 'Users',
            value: '/',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M17 19.5c0-1.657-2.239-3-5-3s-5 1.343-5 3m14-3c0-1.23-1.234-2.287-3-2.75M3 16.5c0-1.23 1.234-2.287 3-2.75m12-4.014a3 3 0 1 0-4-4.472M6 9.736a3 3 0 0 1 4-4.472m2 8.236a3 3 0 1 1 0-6a3 3 0 0 1 0 6" /></svg>,
            subitem: [],
            key: 'p_users'
        },
        {
            name: 'Auctions',
            value: '/item-listing',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" viewBox="0 0 1024 1024"><path fill="currentColor" d="M704 192h160v736H160V192h160v64h384zM288 512h448v-64H288zm0 256h448v-64H288zm96-576V96h256v96z" /></svg>,
            subitem: [],
            key: 'p_auctions'
        },
        {
            name: 'Bid Management',
            value: '/bid-management',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" viewBox="0 0 24 24"><g fill="none" fillRule="evenodd"><path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" /><path fill="currentColor" d="M2.686 10.462a2.5 2.5 0 0 0 0 3.536l2.829 2.828a2.5 2.5 0 0 0 4.242-2.121l.973-.973l6.078 7.014a2.793 2.793 0 1 0 3.94-3.94l-7.013-6.079l.972-.972a2.5 2.5 0 0 0 2.121-4.243L14 2.685a2.5 2.5 0 0 0-4.243 2.122l-4.95 4.949a2.496 2.496 0 0 0-2.12.707M12.94 8.695l-4.242 4.242l-2.122-2.121l4.243-4.243zM4.1 12.584a.5.5 0 1 1 .707-.708l2.829 2.829a.5.5 0 1 1-.707.707zm7.778-7.779a.5.5 0 1 1 .707-.707l2.829 2.829a.5.5 0 1 1-.707.707zm6.442 14.631l-6.008-6.932l.194-.195l6.933 6.008a.793.793 0 1 1-1.12 1.12" /></g></svg>,
            subitem: [],
            key: 'p_bid_manage'
        },
        {
            name: 'Reports',
            value: '',
            icon: <List />,
            subitem: [
                {
                    name: "Sales History",
                    value: "/sales-history",
                    icon: <History />
                },
            ],
            key: 'p_reports'
        },
        {
            name: 'Badge System',
            value: '/badge',
            icon: <Badge />,
            subitem: [],
            key: 'p_badge_system'
        },
        {
            name: 'Disputes',
            value: '/disputes',
            icon: <Badge />,
            subitem: [],
            key: 'p_disputes'
        },
        {
            name: 'Flag Comments',
            value: '/flag-comments',
            icon: <Flag />,
            subitem: [],
            key: 'p_flags'
        },
        {
            name: 'Staff Management',
            value: '/staff',
            icon: <Badge />,
            subitem: [],
            key: 'p_staff'
        },
        {
            name: 'CMS Pages',
            value: '/cms-pages',
            icon: <TocOutlined />,
            subitem: [
                {
                    name: "Home Pages",
                    value: "/cms-pages/homepages",
                    icon: <Home />
                },
                {
                    name: "Sell an Item",
                    value: "/cms-pages/sell-an-item",
                    icon: <SellOutlined />
                },
                {
                    name: "FAQ's",
                    value: '/cms-pages/faq',
                    icon: <FormatQuote />
                },
                {
                    name: "Privacy Policy",
                    value: '/cms-pages/privacy-policy',
                    icon: <PrivacyTip />
                },
                {
                    name: "Terms and Conditions",
                    value: '/cms-pages/terms',
                    icon: <FormatQuote />
                },
                {
                    name: "What's InsertBid",
                    value: '/cms-pages/what-is-insert-bids',
                    icon: <svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" viewBox="0 0 24 24"><g fill="none" fillRule="evenodd"><path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" /><path fill="currentColor" d="M2.686 10.462a2.5 2.5 0 0 0 0 3.536l2.829 2.828a2.5 2.5 0 0 0 4.242-2.121l.973-.973l6.078 7.014a2.793 2.793 0 1 0 3.94-3.94l-7.013-6.079l.972-.972a2.5 2.5 0 0 0 2.121-4.243L14 2.685a2.5 2.5 0 0 0-4.243 2.122l-4.95 4.949a2.496 2.496 0 0 0-2.12.707M12.94 8.695l-4.242 4.242l-2.122-2.121l4.243-4.243zM4.1 12.584a.5.5 0 1 1 .707-.708l2.829 2.829a.5.5 0 1 1-.707.707zm7.778-7.779a.5.5 0 1 1 .707-.707l2.829 2.829a.5.5 0 1 1-.707.707zm6.442 14.631l-6.008-6.932l.194-.195l6.933 6.008a.793.793 0 1 1-1.12 1.12" /></g></svg>,
                },
                {
                    name: "About Us",
                    value: '/cms-pages/about-us',
                    icon: <FormatQuote />
                },
                {
                    name: "Contact Us",
                    value: '/cms-pages/contact-us',
                    icon: <ContactMail />
                },
                {
                    name: "How It Works",
                    value: '/cms-pages/how-it-works',
                    icon: <HowToReg />
                }
            ],
            key: 'p_cms'
        },
    ];

    return (
        <div className="d-flex bg-light-gray sidebar-main-container">
            <div className="sidebar-menu-section"
                style={{
                    display: "flex",
                    height: "100vh",
                    position: "sticky"
                }}
            >
                <Sidebar>
                    <Menu>
                        {items.map((item) => <>
                            {((user?.role === 'staff') && (user?.permission[item?.key] > 0)) || (user?.role !== 'staff') ? item.subitem.length === 0 ?
                                <MenuItem className={item?.value === location.pathname ? "is-active" : "not-active"} component={<Link to={`${item?.value}`} />} onClick={() => { setOpen(null); }} >
                                    {item.icon} {item.name}
                                </MenuItem>
                                :
                                <SubMenu label={item.name} icon={item.icon} open={(item.subitem.find(r => r.value === location.pathname) || (open === item.value)) ? true : false} className={item?.value === location.pathname ? "is-active" : "not-active"} onOpenChange={(open) => setOpen(open ? item.value : null)} >
                                    {item.subitem.map(subItem =>
                                        <MenuItem key={subItem.name} className={subItem?.value === location.pathname ? "is-active" : "not-active"} component={<Link to={`${subItem?.value}`} />}>
                                            {subItem.icon} {subItem.name}
                                        </MenuItem>
                                    )}
                                </SubMenu>
                                : ''}
                        </>)}
                        {user?.role === "staff" && <MenuItem className={location.pathname === '/change-password' ? "is-active" : "not-active"} component={<Link to={'/change-password'} />} >
                            <Password /> Change Password
                        </MenuItem>}
                    </Menu>
                </Sidebar>
            </div>
        </div>
    )
};

export default AdminSideBar;
