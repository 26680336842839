import React, { useEffect, useRef, useState } from "react";
import { FormControl, FormLabel, Input } from "@mui/joy";
import { Accordion, Button, Form } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { create_faq_question, get_all_faq_questions, upload_cms_photo } from "../../reduxData/cms/cmsAction";
import { upload_sell_item_photo } from "../../reduxData/user/userAction";
import { useSelector } from "react-redux";

const { REACT_APP_BASE_URL } = process.env;

const HomeContent = ({ faqItems }) => {
    const {user}=useSelector(state=>state.auth);
    const [isPermission,setIsPermission]=useState(false);
    const [isPermissionDelete,setIsPermissionDelete]=useState(false);
    useEffect(()=>{
        setIsPermission(((((user?.role==='staff') && (user?.permission?.p_staff>1)) || (user?.role!=='staff')))?true:false);
        setIsPermissionDelete(((((user?.role==='staff') && (user?.permission?.p_staff>2)) || (user?.role!=='staff')))?true:false);
    },[user]);
    const [formData, setFormData] = useState({
        // content: '',
        slide1: {
            title: "",
            heading: "",
            subheading: "",
            buttontitle: ""
        },
        slide2: {
            title: "",
            heading: "",
            subheading: "",
            buttontitle: ""
        },
        // contentwo: '',
        imagePath: '',
        status: false,
    });

    const [errors, setErrors] = useState({
        // content: '',
        imagePath: '',
        // contenttwo: '',
        slide1: {
            title: false,
            heading: false,
            subheading: false,
            buttontitle: false
        },
        slide2: {
            title: false,
            heading: false,
            subheading: false,
            buttontitle: false
        },
    });
    const [isImgEdit, setIsImgEdit] = useState(false);
    const [removePhotos, setRemovePhotos] = useState([]);

    const dispatch = useDispatch();
    const imageRef = useRef();
    const handleClick = () => {
        imageRef.current.click();
    };

    const modules = {
        toolbar: [
            [{ 'font': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'align': [] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'image', 'video'],
        ]
    };

    const handleChange = (value, label) => {

        switch (label) {
            // case 'content':
            //     setErrors({ ...errors, content: !value || value === '' ? "Content is required" : null });
            //     setFormData({ ...formData, content: value });
            //     break;
            case 'contentwo':
                setErrors({ ...errors, contenttwo: !value || value === '' ? "Content is required" : null });
                setFormData({ ...formData, contentwo: value });
                break;
            default:
                setFormData({ ...formData, [label]: value });
                break;
        }
    };

    const handleSlides = (value, label, sublabel) => {
        setFormData((prev) => ({
            ...prev, [label]: { ...prev[label], [sublabel]: value, }
        }));
        setErrors((prev) => ({
            ...prev, [label]: { ...prev[label], [sublabel]: value === "" ? true : false }
        }))
    };

    const handleImageChange = async (e) => {
        const imageUpload = [e.target.files[0]];
        const photoData = await upload_cms_photo(imageUpload, localStorage.getItem("token"));
        
        if (photoData !== undefined) {
            const photosFiles = photoData?.data?.path[0];
            setFormData({ ...formData, imagePath: photosFiles });
            setErrors({ ...errors, imagePath: null });
            setIsImgEdit(true);
        }
    };

    const handleStatus = (e) => {
        const { checked } = e.target;
        if (checked) {
            setFormData({ ...formData, status: true });
        } else {
            setFormData({ ...formData, status: false });
        }
    };

    const handleValid = (errdata) => {
        if (!errdata) return;

        let newErr = Object.values(errdata).some(err =>
            typeof err === 'object' && err !== null
                ? Object.values(err).some(val => val === true)
                : err !== null
        );
        // console.log("Errorrororr", newErr);

        return newErr;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let newErrors = {
            imagePath: formData?.imagePath?.trim() === "" ? "Image is required" : null,
            slide1: {
                title: !formData?.slide1?.title || formData?.slide1?.title === "" ? true : false,
                heading: !formData?.slide1?.heading || formData?.slide1?.heading === "" ? true : false,
                subheading: !formData?.slide1?.subheading || formData?.slide1?.subheading === "" ? true : false,
                buttontitle: !formData?.slide1?.buttontitle || formData?.slide1?.buttontitle === "" ? true : false
            },
            slide2: {
                title: !formData?.slide2?.title || formData?.slide2?.title === "" ? true : false,
                heading: !formData?.slide2?.heading || formData?.slide2?.heading === "" ? true : false,
                subheading: !formData?.slide2?.subheading || formData?.slide2?.subheading === "" ? true : false,
                buttontitle: !formData?.slide2?.buttontitle || formData?.slide2?.buttontitle === "" ? true : false
            },
        };

        setErrors(newErrors);

        if (handleValid(newErrors)) return;


        const homedata = {
            name: "home_slides_content",
            type: "home_slides_content",
            status: formData?.status,
            content: {
                slide1: formData?.slide1,
                slide2: formData?.slide2,
                imagePath: formData?.imagePath,
            },
            removePhotos: isImgEdit === true && removePhotos?.length > 0 ? removePhotos : []
        };

        // console.log("Data to be submitted====>", homedata);
        await create_faq_question(localStorage.getItem("token"), homedata, faqItems[0]?._id);
        await get_all_faq_questions(localStorage.getItem("token"), "home_slides_content", dispatch);
        setIsImgEdit(false);

        // if (contentwo && imagePath) {
        //     const quest_data = {
        //         name: "home_content",
        //         type: "home_content",
        //         status: formData.status,
        //         content: {
        //             // content1: formData?.content,
        //             content: formData?.contentwo,
        //             imagePath: formData?.imagePath,
        //         }
        //     };
        //     await create_faq_question(localStorage.getItem("token"), quest_data, faqItems[0]?._id);
        //     await get_all_faq_questions(localStorage.getItem("token"), "home_content", dispatch);
        // }
    };

    useEffect(() => {
        const handleQuest = async () => {
            await get_all_faq_questions(localStorage.getItem("token"), "home_slides_content", dispatch);
        };
        handleQuest();
    }, []);

    useEffect(() => {
        if (faqItems?.length > 0) {
            setFormData({
                slide1: faqItems[0]?.content?.slide1,
                slide2: faqItems[0]?.content?.slide2,
                imagePath: faqItems[0]?.content?.imagePath,
                status: faqItems[0]?.status,
            });
            setRemovePhotos(prev => [...prev, faqItems[0]?.content?.imagePath]);
        }
    }, [faqItems]);

    return (
        <div className="container">
            <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <h4 className="mb-0 fw-bold">Home Content</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <Form onSubmit={handleSubmit}>

                                <FormControl className="mt-2 mb-2">
                                    <FormLabel className="fw-600 fs-5">Slide 1</FormLabel>
                                    <div className="d-flex flex-wrap gap-2">

                                        <FormControl className="mt-2 mb-1 col-md-5">
                                            <FormLabel className="fw-600">Slide Title</FormLabel>
                                            <Input
                                                type="text"
                                                name="answer"
                                                value={formData?.slide1?.title}
                                                disabled={!isPermission}
                                                onChange={(e) => handleSlides(e.target.value, "slide1", "title")}
                                            />
                                            {errors?.slide1?.title && <div className="error_msg">
                                                Slide title is required
                                            </div>
                                            }
                                        </FormControl>

                                        <FormControl className="mt-2 mb-1 col-md-5">
                                            <FormLabel className="fw-600">Heading</FormLabel>
                                            <Input
                                                type="text"
                                                name="answer"
                                                value={formData?.slide1?.heading}
                                                disabled={!isPermission}
                                                onChange={(e) => handleSlides(e.target.value, "slide1", "heading")}
                                            />
                                            {errors?.slide1?.heading && <div className="error_msg">
                                                Heading is required
                                            </div>
                                            }
                                        </FormControl>

                                        <FormControl className="mt-2 mb-1 col-md-5">
                                            <FormLabel className="fw-600">Sub Heading</FormLabel>
                                            <Input
                                                type="text"
                                                name="answer"
                                                value={formData?.slide1?.subheading}
                                                disabled={!isPermission}
                                                onChange={(e) => handleSlides(e.target.value, "slide1", "subheading")}
                                            />
                                            {errors?.slide1?.subheading && <div className="error_msg">
                                                Sub Heading is required
                                            </div>
                                            }
                                        </FormControl>

                                        <FormControl className="mt-2 mb-1 col-md-5">
                                            <FormLabel className="fw-600">Button Title</FormLabel>
                                            <Input
                                                type="text"
                                                name="answer"
                                                value={formData?.slide1?.buttontitle}
                                                disabled={!isPermission}
                                                onChange={(e) => handleSlides(e.target.value, "slide1", "buttontitle")}
                                            />
                                            {errors?.slide1?.buttontitle && <div className="error_msg">
                                                Button title is required
                                            </div>
                                            }
                                        </FormControl>

                                    </div>
                                </FormControl>

                                <FormControl className="mt-2 mb-2">
                                    <FormLabel className="fw-600 fs-5">Slide 2</FormLabel>

                                    <div className="d-flex flex-wrap gap-2">
                                        <FormControl className="mt-2 mb-1 col-md-5">
                                            <FormLabel className="fw-600">Slide Title</FormLabel>
                                            <Input
                                                type="text"
                                                name="answer"
                                                value={formData?.slide2?.title}
                                                disabled={!isPermission}
                                                onChange={(e) => handleSlides(e.target.value, "slide2", "title")}
                                            />
                                            {errors?.slide2?.title && <div className="error_msg">
                                                Slide title is required
                                            </div>}

                                        </FormControl>
                                        <FormControl className="mt-2 mb-1 col-md-5">
                                            <FormLabel className="fw-600">Heading</FormLabel>
                                            <Input
                                                type="text"
                                                name="answer"
                                                value={formData?.slide2?.heading}
                                                disabled={!isPermission}
                                                onChange={(e) => handleSlides(e.target.value, "slide2", "heading")}
                                            />
                                            {errors?.slide2?.heading && <div className="error_msg">
                                                Heading is required
                                            </div>}

                                        </FormControl>
                                        <FormControl className="mt-2 mb-1 col-md-5">
                                            <FormLabel className="fw-600">Sub Heading</FormLabel>
                                            <Input
                                                type="text"
                                                name="answer"
                                                value={formData?.slide2?.subheading}
                                                disabled={!isPermission}
                                                onChange={(e) => handleSlides(e.target.value, "slide2", "subheading")}
                                            />
                                            {errors?.slide2?.subheading && <div className="error_msg">
                                                Sub Heading is required
                                            </div>}

                                        </FormControl>
                                        <FormControl className="mt-2 mb-1 col-md-5">
                                            <FormLabel className="fw-600">Button Title</FormLabel>
                                            <Input
                                                type="text"
                                                name="answer"
                                                value={formData?.slide2?.buttontitle}
                                                disabled={!isPermission}
                                                onChange={(e) => handleSlides(e.target.value, "slide2", "buttontitle")}
                                            />
                                            {errors?.slide2?.buttontitle && <div className="error_msg">
                                                Button title is required
                                            </div>}

                                        </FormControl>
                                    </div>
                                </FormControl>


                                {/* <FormControl className="mt-2 mb-2">
                                    <FormLabel className="fw-600">Content</FormLabel>
                                    <Input
                                        type="text"
                                        name="answer"
                                        value={formData.contentwo}
                                        onChange={(e) => handleChange(e.target.value, 'contentwo')}
                                    />
                                    {errors?.contenttwo && <div className="error_msg">{errors?.contenttwo}</div>}
                                </FormControl> */}

                                <FormControl className="mt-2 mb-2">
                                    <FormLabel className="fw-600">Image</FormLabel>
                                    {formData?.imagePath &&
                                        <div className="position-relative pb-3 d-flex h-100" >
                                            <img
                                                src={`${REACT_APP_BASE_URL}${formData?.imagePath}`}
                                                style={{
                                                    height: '400px',
                                                    width: '100%'
                                                }}
                                            />
                                            {isPermissionDelete && <div className="position-absolute upload-file-close">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm rounded-circle"
                                                    onClick={() => { setFormData({ ...formData, imagePath: '' }) }}>
                                                    <i class="fa-solid fa-xmark color-white"></i>
                                                </button>
                                            </div>}
                                        </div>
                                    }
                                    <input
                                        type="file"
                                        className="d-none"
                                        accept="image/png, image/jpg, image/jpeg, image/gif"
                                        ref={imageRef}
                                        disabled={!isPermission}
                                        onChange={(e) => {
                                            handleImageChange(e);
                                            e.target.value = "";
                                        }}
                                    />+
                                    <Button className="btn upload-button" disabled={!isPermission} onClick={handleClick}>Upload</Button>
                                    {errors?.imagePath && <div className="error_msg">{errors?.imagePath}</div>}
                                </FormControl>
                                <FormControl className="mt-1 mb-2">
                                    <FormLabel className="fw-600">Status</FormLabel>
                                    <div class="form-check form-switch switch-large">
                                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={formData.status} disabled={!isPermission} onChange={handleStatus} />
                                    </div>
                                </FormControl>
                                <Button type="submit" className="btn common-button" disabled={!isPermission}>Save</Button>
                            </Form>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        faqItems: state.cms.faqItems,
    }
};

export default connect(mapStateToProps)(HomeContent);
