import React, { useEffect } from "react";
import Avatar from "@mui/joy/Avatar";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/joy/IconButton";
import Logo from "../images/logo.png";
// import { Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import { admin_profile } from "../reduxData/auth/authAction";
import ProfileIcon from '@mui/icons-material/PersonOutline';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { Dropdown } from "react-bootstrap";
import DefaultImg from '../images/no_profile.png';


export const Header = () => {
  const [profile, setProfile] = React.useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await admin_profile(localStorage.getItem('token'));
        setProfile(res)
      } catch (error) {
        console.error("Error fetching auction categories:", error);
      }
    };

    fetchData();
  }, []);

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div>
      <nav className="cream-box d-flex justify-content-between cont">
        <span>
          <Link className="navbar-brand m-2" to="/dashboard">
            <img src={Logo} alt="logo" className="logo" />
          </Link>
        </span>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* <Dropdown>
          <MenuButton
            slots={{ root: IconButton }}
            slotProps={{
              root: { variant: "outlined", color: "white" },
            }}
          >
            <img
              className="mx-2"
              src={`${process.env.REACT_APP_BASE_URL}${profile?.profile_pic}`}
              variant="soft"
              alt=""
              height={35} width={35}
              style={{ borderRadius: '50%' }}
            />
          </MenuButton>
          <Menu className="dropdown-list">
            <Link className="navbar-brand" to={`/profile`}>
              <MenuItem className="menu-item-list"> <ProfileIcon /> Profile</MenuItem>
            </Link>
            <hr className="m-0 p-0" />

            <Link>
              <MenuItem className="menu-item-list" onClick={logout}><LogoutRoundedIcon /> Logout</MenuItem>
            </Link>
          </Menu>
        </Dropdown> */}

        <Dropdown
          id="dropdown-basic-button"
          className="drop-downlist"
          variant="light"
        // onClick={handleShow}
        >
          <Dropdown.Toggle id="dropdown-basic" style={{ width: '100%', paddingRight: '18px', backgroundColor: '#FAB406', borderRadius: '10px', border: '1px solid black' }}>
            {profile?.profile_pic ?
              <img src={`${process.env.REACT_APP_BASE_URL}${profile?.profile_pic}`} height={30} width={30} style={{ borderRadius: '50%' }} alt="" />
              :
              <img src={DefaultImg} alt="img not found" height={30} width={30} style={{ borderRadius: '50%' }}  />
            }

          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => navigate('/profile')}><div className="dropdown-memulist">
              <ProfileIcon /> Profile</div>
            </Dropdown.Item>
            <Dropdown.Item onClick={logout}><LogoutRoundedIcon /> Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </nav>
    </div>
  );
};
export default Header;
