import React, { useState } from "react";
import { Avatar, Button, Container, Grid, Typography } from "@mui/joy";
import Box from "@mui/joy/Box";
import { update_admin } from "../reduxData/user/userAction";
import { useNavigate } from "react-router-dom";
import FormLabel from "@mui/joy/FormLabel";
import DefaultImg from '../images/no_profile.png';
import { Cursor } from "@phosphor-icons/react";

const AdminProfile = () => {
  let user = null;
  if (JSON.parse(localStorage.getItem("profile"))) {
    user = JSON.parse(localStorage.getItem("profile"));
  } else {
    user = JSON.parse(localStorage.getItem("auth"));
  }
  const getDate = (date) => {
    const dateTime = new Date(date);
    const formattedDate = dateTime.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    return formattedDate;
  };

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: user.name,
    email: user.email,
    bio: user.bio,
    profile_pic: user.profile_pic, 
    selectedProfilePic: user.profile_pic ? null : DefaultImg,
    binaryImage:null
  });

  const [errros, setErrors]= useState({
    name:''
  })

  const handleChange = (e) => {
    if(e.target.name == 'name'){
      if(!e.target.value){
        setErrors({...errros, [e.target.name]:"Name is required"})
      }else{
        setErrors({...errros, [e.target.name]:""})
      }
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData({ ...formData, selectedProfilePic: reader.result,binaryImage:file });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // const handleSave = () => {
  //   const token = localStorage.getItem('token')
  //   console.log("Form data", formData)
  //   if(formData?.binaryImage){
  //     formData.profile_pic = formData?.binaryImage
  //     delete formData.selectedProfilePic
  //   }
  //   update_admin(formData, navigate,token);
  // };

  const handleSave = () => {
    if(errros.name){
      return
    }
    const token = localStorage.getItem('token');
    console.log("Form data", formData);
  
    const formDataToSend = new FormData();
  
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('bio', formData.bio);
  
    if (formData.selectedProfilePic) {
      formDataToSend.append('profile_pic', formData.binaryImage);
    } else {
    }
  
    update_admin(formDataToSend, navigate, token);
  };
  
  return (
    <Box
      sx={{
        backgroundImage: `url('your-background-image-url')`,
        backgroundSize: "cover",
        minHeight: "100vh",
        paddingTop: "20px", // Adjust as needed
      }}
    >
      <Container maxWidth="md">
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 4, backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
              <Avatar
                sx={{
                  width: { xs: 120, sm: 150 },
                  height: { xs: 120, sm: 150 },
                  mx: "auto",
                  my: 2,
                }}
                style={{ cursor: "pointer" }} 
                alt
                ="User Avatar"
                src={formData.selectedProfilePic || `${process.env.REACT_APP_BASE_URL}${formData.profile_pic}`}
              />
              <div className="text-center fw-600">
              <label htmlFor="avatar-input">
               Update Profile nnn</label>
                <input
                  id="avatar-input"
                  type="file"
                  accept="image/*"
                  className="form-control"
                  onChange={handleImageChange}
                  style={{ display: "none" , cursor: "pointer" }} 
                />
                </div>
              
              <Typography variant="h4" className="mb-2" gutterBottom>
              <FormLabel className="mb-2">Name:</FormLabel>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  className="form-control"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errros?.name && <div className="error_msg">{errros?.name}</div>}
              </Typography>
              <Typography variant="body1" className="mb-2"  gutterBottom>
              <FormLabel className="mb-2">Email:</FormLabel>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                  className="form-control"
                  value={formData.email}
                  onChange={handleChange}
                  disabled
                />
              </Typography>
              <Typography variant="body1" className="mb-2"  gutterBottom>
              <FormLabel className="mb-2">Bio:</FormLabel>
                <textarea
                  type="text"
                  name="bio"
                  placeholder="Enter bio"
                  className="form-control"
                  value={formData.bio}
                  onChange={handleChange}
                />
              </Typography>
              <Typography variant="body1" className="text-dark" align="center" gutterBottom>
                Last Login Date: {getDate(user.loginDate)}
              </Typography>
              <Button
                variant="unset"
                className="btn common-button"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AdminProfile;
