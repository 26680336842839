import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Table } from "@mui/joy";
import { Link } from "react-router-dom";
import { delete_user, get_export_users, get_users, gotToExport } from "../reduxData/user/userAction";
import { Box, Input } from "@mui/joy";
import CustomPagination from "./CustomPagination";
import EmptyData from "./EmptyData";
import { Token } from "@mui/icons-material";
import { toast } from "react-toastify";
import moment from "moment";
import DeleteContent from "../Modals/DeleteContent";
import { useSelector } from "react-redux";

const UserManagement = ({ allUsers, totalUsers, isLoading }) => {
  const [userManagement, setUserManagement] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [editData, setEditData] = useState(null);
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await get_users(
          dispatch,
          localStorage.getItem("token"),
          1,
          10,
          name,
          email
        );
        setUserManagement(data?.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [currentPage, perPage, name, email]);

  const changeDOJFormat = (data) => {
    const joinDate = moment(data).format('MM/DD/YYYY');
    return joinDate;
  }

  const exportData = async (userId) => {
    const data = await get_export_users(dispatch, localStorage.getItem("token"), userId);
    if (data) {
      gotToExport(data, 'users');
    } else {
      toast.error("Can't export users");
    }
  }
  return (
    <div className="container">
      <Box className="d-flex mb-4 align-items-center justify-content-between">
        <h3 className="mb-0 fw-600">Users ({totalUsers})</h3>

        <div className="d-flex align-items-center justify-content-between">
          <div className="mx-3">
            <Input
              id="nameInput"
              type="text"
              placeholder="Name of user"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <Input
              id="emailInput"
              type="text"
              placeholder="Email of user"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {(((user?.role === 'staff') && (user?.permission?.p_users > 1)) || (user?.role !== 'staff')) && <button type="button" className="btn common-button ms-3" disabled={isLoading} onClick={() => exportData(null)}>
            <i className="fa fa-download me-1"></i>
            Export
          </button>}
        </div>
      </Box>
      <div className="table-responsive">
        <table className="table user-management-table table-hover">
          <thead className="border-gray">
            <th scope="col">Name of User</th>
            <th scope="col">Username</th>
            <th scope="col" className="text-center">Date of Joining</th>
            <th scope="col">Email</th>
            {/* <th scope="col" className="text-center">Phone Number</th> */}
            <th scope="col" className="text-center">Status</th>
            {(((user?.role === 'staff') && (user?.permission?.p_users > 1)) || (user?.role !== 'staff')) && <th scope="col" className="text-center">Action</th>}
          </thead>
          <tbody>
            {totalUsers > 0 ?
              allUsers.map((data, index) => (
                <tr key={index}>
                  <td>{data.name ? data.name : '-'}</td>
                  <td>{data?.user_name ? data?.user_name : '-'}</td>
                  <td className="text-center">{changeDOJFormat(data.createdAt)}</td>
                  <td>{data.email}</td>
                  {/* <td className="text-center">{data.phoneNumber ? data.phoneNumber : '-'}</td> */}
                  <td className="text-center">
                    {data?.isDeactive ? (

                      "Inactive"

                    ) : (

                      "Active"
                    )}
                  </td>
                  {(((user?.role === 'staff') && (user?.permission?.p_users > 1)) || (user?.role !== 'staff')) && <td className="text-center">
                    {/* <button className="btn btn-sm btn-main m-2"> */}
                    <Link
                      className="navbar-brand"
                      to="javascript:void(0)"
                      disabled={isLoading}
                      onClick={() => exportData(data._id)}
                    >
                      <i className="fa fa-download edit"></i>
                    </Link>
                    <Link
                      className="navbar-brand mx-2"
                      to={`/editUser/${data._id}`}
                    >
                      <i className="far fa-edit edit"></i>
                    </Link>
                    {/* </button> */}
                    {(((user?.role === 'staff') && (user?.permission?.p_users > 2)) || (user?.role !== 'staff')) && <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      className="cursor-pointer delete-icon bi bi-trash3-fill"
                      onClick={() => { setEditData(data); setIsDelete(true); }}
                    >
                      <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                    </svg>}
                  </td>}
                </tr>
              )) : <EmptyData />}
          </tbody>
        </table>
      </div>

      <div className="mt-3">
        {totalUsers > 0 && (
          <CustomPagination
            total={totalUsers}
            onPageChange={(page, perPage) => { get_users(dispatch, localStorage.getItem("token"), page, perPage); }}
          />
        )}
        {/* <button
          className="btn btn-sm me-2"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </button>
        <span>Page {currentPage}</span>
        <button
          className="btn btn-sm ms-2"
          disabled={!userManagement || userManagement.length < perPage}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </button> */}
      </div>
      <DeleteContent show={isDelete} handleClose={() => { setEditData(null); setIsDelete(false); }} data={editData} heading={"User"} otherLine={"User"} isFaq={'userDelete'} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allUsers: state.user.allUsers,
    totalUsers: state.user.totalUsers,
    isLoading: state.loading.isLoading
  };
};

export default connect(mapStateToProps)(UserManagement);
