import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_ALL_BID_AUCTIONS, GET_ALL_CATEGORIES, GET_ALL_PAST_AUCTIONS, GET_ALL_RUNNING_AUCTIONS, GET_ALL_USERS, GET_BADGE_LISTS, GET_BID_TOTAL, GET_ITEM_LISTING, GET_ITEM_TOTAL, GET_PAST_TOTAL, GET_RUNNING_TOTAL, GET_SINGLE_STAFF, GET_STAFF_LISTING, GET_TOTAL_CATEGORIES, GET_TOTAL_USERS, USER_DISPUTES, USER_FLAG_COMMENTS } from './userTypes';
import { start_loading, stop_loading } from '../loader/loaderAction';
import moment from 'moment';

const { REACT_APP_BASE_URL } = process.env;
const get_token = () => {
  let token = localStorage.getItem('token')
  return token;
}
// const token = get_token()
export const DESIGNATION = [
  {
     label: 'Reviewer',
     value: 'reviewers'
  },
  {
      label: 'Manager',
     value: 'managers',
  },
  {
      label: 'Owner',
      value: 'owners'
  }
];
export const show_success = (message) => {
  toast(message)
}

const headers = {
  'Content-Type': 'application/json',
  'x-access-token': get_token(),
};

export const check_token_expired_logout = (data) => {
  if (data?.request?.status === 401) {
    localStorage.clear()
    window.location.reload()
  }
}
export const gotToExport=(data,label)=>{
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${label}(${moment()?.format("MMM DD, YYYY")?.replace(/,/g, '')}).csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
export const upload_image = async (dispatch, token, data) => {
  const headers = {
    'x-access-token': token,
  };
  try {
    const res = await axios.post(`${REACT_APP_BASE_URL}add-item/mediaImage`, data,{ headers });
    if (res?.data) {
      return res?.data;
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    check_token_expired_logout(error)
    console.error("Error:", error);
  }
};
export const get_export_users = async (dispatch, token, userId) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  };
  dispatch(start_loading());
  try {
    const res = await axios.post(`${REACT_APP_BASE_URL}admin/export-user`, {userIds:userId?[userId]:[]},{ headers });
    if (res?.data) {
      return res?.data;
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    check_token_expired_logout(error)
    console.error("Error:", error);
  }finally{
    dispatch(stop_loading());
  }
};

export const update_password = async (userdata, token, dispatch) => {
  try {
    const url = `${REACT_APP_BASE_URL}profile/change-password`;
    const HEADERS = {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      }
    };
    const res = await axios.put(url, JSON.stringify(userdata), HEADERS);
    if (res.data && res.data.status) {
      toast.success(res.data.message, { toastId: "updatpwd", autoClose: 1000 });
      return true;
    } else {
      toast.error(res.data.message, { toastId: "updatedpwderr", autoClose: 1000 });
      return false;
    }
  } catch (error) {
    toast.error(error.response.data.error, { toastId: "updatedwwwww", autoClose: 1000 });
    // catch_errors_handle(error);
    check_token_expired_logout(error);
  }
};

export const get_export_sales = async (dispatch, token, userId,year) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  };
  dispatch(start_loading());
  try {
    const res = await axios.post(`${REACT_APP_BASE_URL}admin/export-sales-report`, {user_id:userId==='ALL'?null:userId,start_date:moment().year(year).startOf('year'),end_date:moment().year(year).endOf('year')},{ headers });
    if (res?.data) {
      return res?.data;
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    check_token_expired_logout(error)
    console.error("Error:", error);
  }finally{
    dispatch(stop_loading());
  }
};
export const withdraw_auction = async (dispatch, token, itemId) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  };
  dispatch(start_loading());
  try {
    const res = await axios.put(`${REACT_APP_BASE_URL}admin/auction-withdrawn`, {itemId:itemId},{ headers });
    if (res?.data) {
      return res?.data;
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    check_token_expired_logout(error);
  }finally{
    dispatch(stop_loading());
  }
};
export const get_users = async (dispatch, token, currentPage, perPage, name, email) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  };

  try {
    const res = await axios.get(`${REACT_APP_BASE_URL}admin/usersList?page=${currentPage}&limit=${perPage}&name=${name ? name : ''}&email=${email ? email : ''}`, { headers });
    if (res?.data?.data) {
      dispatch({ type: GET_ALL_USERS, payload: res?.data?.data });
      dispatch({ type: GET_TOTAL_USERS, payload: res.data.total });
      return res?.data;
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    check_token_expired_logout(error)
    console.error("Error:", error);
  }
};


export const get_particular_user = async (id, token) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  };
  try {
    const res = await axios.get(`${REACT_APP_BASE_URL}admin/userDetails?userId=${id}`, { headers });
    if (res?.data?.data) {
      return res?.data?.data;
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    console.error("Error:", error);
    check_token_expired_logout(error)
  }
}

export const update_user = async (id, data, navigate, token) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  };
  if (data?.isDeactive === 'Deactivate') {
    data.isDeactive = true
  } else {
    data.isDeactive = false
  }

  try {
    const res = await axios.put(`${REACT_APP_BASE_URL}admin/editUser?userId=${id}`, data, { headers });
    if (res?.data?.data) {
      show_success(res?.data?.message)
      navigate('/')
      return true;
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    console.error("Error:", error);
    check_token_expired_logout(error)
  }
}
export const delete_user = async (dispatch,token,userId) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  };
  dispatch(start_loading());
  try {
    const res = await axios.delete(`${REACT_APP_BASE_URL}admin/deletetUser?userId=${userId}`, { headers });
    if (res?.data) {
      show_success(res?.data?.message)
      return true;
    } 
  } catch (error) {
    check_token_expired_logout(error)
  }finally{
    dispatch(stop_loading());
  }
}
export const update_admin = async (data, navigate, token) => {
  const headers = {
    'x-access-token': token,
  };

  try {
    const res = await axios.put(`${REACT_APP_BASE_URL}admin/editAdminProfile`, data, { headers });
    if (res.status && res?.data?.data) {

      localStorage.setItem('profile', JSON.stringify(res?.data?.data));
      show_success("Profile Updated Successfully");
      setTimeout(() => {
        window.location.reload()
      }, 1200)
      return res?.data?.data;
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    console.error("Error:", error);
    check_token_expired_logout(error);
  }
};


export const get_all_sell_Item = async (token, dispatch, page, limit, listtype, queryCat) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  };
  try {
    const url = `${REACT_APP_BASE_URL}admin/list_for_approval?page=${page}&limit=${limit}&type=${listtype}${queryCat ? `&${queryCat}` : ''}`;
    const res = await axios.get(url, { headers });
    if (res.data && res.data.status) {
      dispatch({ type: GET_ITEM_LISTING, payload: res.data.data });
      dispatch({ type: GET_ITEM_TOTAL, payload: res.data.total });
      return res;
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    console.error("Error:", error);
    check_token_expired_logout(error)
  }
};

export const get_single_sell_Item = async (id) => {
  try {
    const url = `${REACT_APP_BASE_URL}item/detail?slug=${id}`;
    const res = await axios.get(url, { headers });
    if (res.data && res.data.status) {
      return res.data.data;
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    console.error("Error:", error);
    check_token_expired_logout(error)
  }
};

export const edit_item_list = async (user, navigate, token) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  };
  try {
    const res = await axios.post(`${REACT_APP_BASE_URL}item/add`, user, { headers });
    if (res?.data?.status) {
      navigate('/item-listing')
      show_success(res?.data?.message)
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    console.error("Error:", error);
    show_success(error?.response?.data?.messahe)
    check_token_expired_logout(error)
    // Handle error here
  } finally {
  }
};

export const approve_auction = async (itemdata, navigate, token) => {
  try {
    const url = `${REACT_APP_BASE_URL}admin/approve_auction`;
    const HEADERS = {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      }
    };
    await axios.put(url, itemdata, HEADERS).then((result) => {
      if (result?.data && result?.data?.status) {
        navigate('/item-listing')
        show_success(result?.data?.message);
        return result;
      }
    }).catch((error) => {
      toast.error(error?.response?.data?.error);
      check_token_expired_logout(error);
    });
  } catch (error) {
    console.error("Error:", error);
    check_token_expired_logout(error);
  }
};

export const unapprove_auction = async (itemdata, navigate, token) => {

  try {
    const url = `${REACT_APP_BASE_URL}admin/approve_auction`;
    const HEADERS = {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      }
    };
    const res = await axios.put(url, itemdata, HEADERS);

    if (res?.data && res?.data?.status) {
      navigate('/item-listing')
      show_success(res?.data?.message);
      return res;
    }
  } catch (error) {
    console.error("Error:", error);
    check_token_expired_logout(error)
  }
  finally {
  }
};

export const get_past_auctions = async (dispatch, token, page, limit, categories) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  };
  try {
    const url = `${REACT_APP_BASE_URL}admin/past_auction?page=${page}&limit=${limit}${categories ? `&${categories}` : ''}`;
    const res = await axios.get(url, { headers });
    if (res.data && res.data.status) {
      dispatch({ type: GET_ALL_PAST_AUCTIONS, payload: res?.data?.data });
      dispatch({ type: GET_PAST_TOTAL, payload: res.data.total });
      return res;
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    console.error("Error:", error);
    check_token_expired_logout(error)
  }
};

export const add_category = async (data, token) => {
  const headers = {
    'x-access-token': token,
  };

  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}admin/add_category`,
      data,
      { headers }
    );

    // Check if the response is successful (status code 2xx)
    if (response?.status >= 200 && response?.status < 300) {
      show_success(response?.data?.message);
    } else {
      throw new Error('Network response was not ok');
    }

    return response;
  } catch (error) {
    console.error('Error:', error);
    check_token_expired_logout(error);
    throw error; // Rethrow the error to handle it in the calling code if needed
  }
};

export const edit_category = async (data, token, id) => {
  const headers = {
    'x-access-token': token,
  };

  try {
    const response = await axios.put(
      `${REACT_APP_BASE_URL}admin/edit_category/${id}`,
      data, { headers }
    );

    if (!response?.data?.status) {
      throw new Error('Network response was not ok');
    } else {
      show_success(response?.data?.message);
    }

    return response;
  } catch (error) {
    console.error('Error:', error);
    check_token_expired_logout(error);
  }
};

export const apply_feature_category = async (data, token, id) => {
  const HEADERS = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    }
  };

  try {
    const response = await axios.put(`${REACT_APP_BASE_URL}admin/edit_category/${id}`, data, HEADERS);

    if (response.data && response?.data?.status) {
      toast.success(response.data.message, { toastId: "succesone" });
    }
  } catch (error) {
    toast.error(error.response.data.message, { toastId: "suceestwo" });
    console.error('Error:', error);
    check_token_expired_logout(error);
  }
};

export const get_category = async (dispatch, token, page, limit) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  };
  try {
    const url = `${REACT_APP_BASE_URL}admin/list_category?page=${page}&limit=${limit}`;
    const res = await axios.get(url, { headers });
    if (res.data && res.data.status) {
      dispatch({ type: GET_ALL_CATEGORIES, payload: res.data.data });
      dispatch({ type: GET_TOTAL_CATEGORIES, payload: res.data.total });
      return res;
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    console.error("Error:", error);
    check_token_expired_logout(error)
  }
};

export const delete_category = async (id, token) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  };
  try {
    const url = `${REACT_APP_BASE_URL}admin/delete_category?categoryId=${id}`;
    const res = await axios.delete(url, { headers });
    if (res.data && res.data.status) {
      show_success(res?.data?.message)
      return res;
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    console.error("Error:", error);
    check_token_expired_logout(error)
  }
}


export const get_running_auctions = async (dispatch, token, page, limit, categories) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  };
  try {
    const url = `${REACT_APP_BASE_URL}admin/running_auction?page=${page}&limit=${limit}${categories ? `&${categories}` : ''}`;
    const res = await axios.get(url, { headers });
    if (res.data && res.data.status) {
      dispatch({ type: GET_ALL_RUNNING_AUCTIONS, payload: res?.data?.data });
      dispatch({ type: GET_RUNNING_TOTAL, payload: res?.data?.total });
      return res;
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    console.error("Error:", error);
    check_token_expired_logout(error)
  }
}

export const get_all_auction_category = async () => {
  try {
    const url = `${REACT_APP_BASE_URL}admin/list_category`;

    const res = await axios.get(url, { headers });
    if (res.data && res.data.status) {
      return res.data.data
    } else {
    }
  } catch (error) {
    check_token_expired_logout(error);
  }
};

export const sell_video_game = async (userdata, token) => {
  try {
    const url = `${REACT_APP_BASE_URL}item/add`;
    // const formData = new FormData();
    // Object.entries(userdata).forEach(([key, value]) => {
    //   if (key === 'legal_doc') {
    //     value.forEach((docs) => {
    //       formData.append(`legal_doc`, docs);
    //     });
    //   } else if (key === 'photos') {
    //     value.forEach((photo) => {
    //       formData.append(`photos`, photo);
    //     });
    //   } else if (key === 'video' && value) {
    //     value.forEach((vid) => {
    //       formData.append(`video`, vid);
    //     });
    //   } else if (key === 'video' && !value) {
    //     formData.append(`video`, null);
    //   } else if (key === 'removephotos' && value.length > 0) {
    //     value.forEach((photo) => {
    //       formData.append(`removephotos`, photo);
    //     });
    //   } else if (key === "start_date") {
    //     formData.append(`start_date`, new Date(value));
    //   } else if (key === "end_date") {
    //     formData.append(`end_date`, new Date(value));
    //   } else {
    //     formData.append(key, value);
    //   }
    // });
    // const HEADERS = {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     "x-access-token": token,
    //   }
    // };
    const HEADERS = {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      }
    };
    const res = await axios.post(url,  JSON.stringify(userdata), HEADERS);
    if (res.data && res.data.status) {
      toast.success(res.data.message, { toastId: 'itemupdetsws', autoClose: 2000 });
      return true;
    } else {
      toast.error(res.data.message);
      return false;
    }
  } catch (error) {
    // console.log("//////////////", error)
    // catch_errors_handle(error);
  }
};

export const upload_sell_item_photo = async (photo, token) => {
  const formData = new FormData();

  photo.forEach((v) => {
    formData.append(`photos`, v);
  });
  formData.append('type', 'item/image');

  try {
    const url = `${REACT_APP_BASE_URL}add-item/mediaImage`;
    const HEADERS = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": token,
      }
    };
    const res = await axios.post(url, formData, HEADERS);
    if (res.data && res.data.status) {
      toast.success(res.data.message);
      return res;
    } else {
      toast.error(res.error, { toastId: "editphotoerrr", autoClose: 2000 });
      return false;
    }

  } catch (error) {
    toast.error(error.response.data.message);
    // catch_errors_handle(error);
    // check_token_expired_logout(error);
  }
};

export const upload_edit_item_video = async (video, token, dispatch) => {
  // dispatch(start_loading());
  try {
    const url = `${REACT_APP_BASE_URL}add-item/videoupload`;
    const HEADERS = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": token,
      }
    };
    const formData = new FormData();
    video.forEach((v) => {
      formData.append(`video`, v);
    });
    formData.append('type', 'item/video');

    const res = await axios.post(url, formData, HEADERS);
    if (res.data && res.data.status) {
      toast.success(res.data.message);
      return res;
    } else {
      toast.error(res.error, { toastId: "editvideoerrr", autoClose: 2000 });
      return false;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    // catch_errors_handle(error);
  } finally {
    // dispatch(stop_loading());
  }
};

export const upload_sell_item_logal_docs = async (legal_doc, token) => {
  try {
    const url = `${REACT_APP_BASE_URL}add-item/legaldocs`;
    const formData = new FormData();
    legal_doc.forEach((doc) => {
      formData.append('legal_doc', doc);
    });
    formData.append('type', 'item/legalDocs');

    // formData.append('legal_doc', legal_doc);
    const HEADERS = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": token,
      }
    };
    const res = await axios.post(url, formData, HEADERS);
    if (res.data && res.data.status) {
      toast.success(res.data.message);
      return res;
    } else {
      toast.error(res.data.message);
      return false;
    }
  } catch (error) {
    check_token_expired_logout(error);
  }
}


export const get_all_bid_items = async (dispatch, token, search, page, limit) => {
  try {
    const url = `${REACT_APP_BASE_URL}item/all_items?search=${search ? search : ''}&page=${page}&limit=${limit}`;
    const HEADERS = {
      headers: {
        "x-access-token": token,
      }
    };
    const res = await axios.get(url, HEADERS);
    dispatch({ type: GET_ALL_BID_AUCTIONS, payload: res.data.data });
    dispatch({ type: GET_BID_TOTAL, payload: res.data.total });
    return res;
  } catch (error) {
    console.log("bid management", error);
    // check_token_expired_logout(error)
  }
};

export const get_item_bid_detail = async (itemId, token) => {
  try {
    const url = `${REACT_APP_BASE_URL}item/all_bids?itemId=${itemId}`;
    const HEADERS = {
      headers: {
        "x-access-token": token
      }
    };
    const res = axios.get(url, HEADERS);
    return res;
  } catch (error) {
    check_token_expired_logout(error)
  }
}

//badge apis

export const get_badge_lists = async (token, dispatch) => {
  try {
    const url = `${REACT_APP_BASE_URL}admin/list-badge`;
    const HEADERS = {
      headers: {
        "x-access-token": token
      }
    };
    const res = await axios.get(url, HEADERS);
    if (res.data && res.data.status) {
      dispatch({ type: GET_BADGE_LISTS, payload: res.data.data });
    }
  } catch (error) {
    console.log(error);
  }
};

export const add_badge = async (token, badge_data, badgeId) => {
  try {
    const url = badgeId ? `${REACT_APP_BASE_URL}admin/update-badge/${badgeId}` : `${REACT_APP_BASE_URL}admin/add-badge`;
    const HEADERS = {
      headers: {
        'Content-Type': 'application/json',
        "x-access-token": token,
      }
    };
    // const formdata = new FormData();
    // for(let key in badge_data){
    //   formdata.append(key,badge_data[key]);
    // }
    const res = badgeId ? await axios.put(url, JSON.stringify(badge_data), HEADERS) : await axios.post(url, JSON.stringify(badge_data), HEADERS);
    if (res.data && res.data.status) {
      toast.success(res.data.message);
      return true;
    }
  } catch (error) {
    console.log(error);
  }
};

export const delete_badge = async (token, badgeId) => {
  try {
    const url = `${REACT_APP_BASE_URL}admin/delete-badge/${badgeId}`;
    const HEADERS = {
      headers: {
        "x-access-token": token,
      }
    };
    const res = await axios.delete(url, HEADERS);
    if (res.data && res.data.status) {
      toast.success(res.data.message);
      return true;
    }
  } catch (error) {
    console.log(error);
  }
};

export const delete_faq_question = async (token, faqId) => {
  try {
    const url = `${REACT_APP_BASE_URL}admin/delete-content/seller`;
    const HEADERS = {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      data: { id: faqId },
    };

    const res = await axios.delete(url, HEADERS);
    if (res.data && res.data.status) {
      toast.success(res.data.message);
      return true;
    }
  } catch (error) {
    toast.error(error.response.data.message, { toastId: "deltefaw", autoClose: 2000 });
  }
};

export const get_user_sales_history = async (token, data) => {
  try {
    const url = `${REACT_APP_BASE_URL}user/sales-report`;
    const HEADERS = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json"
      }
    };
    const res = await axios.post(url, JSON.stringify(data), HEADERS);
    if (res.data && res.data.status) {
      // console.log(res.data);
      return res.data.data;
    } else {
    }
  } catch (error) {
    toast.error(error.response.data.error, { toastId: "saleshistiewr", autoClose: 2000 });
  }
};
export const get_all_staff = async (token, dispatch, page, limit, search,uid) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  };
  try {
    const url = `${REACT_APP_BASE_URL}admin/staff-list?page=${page}&limit=${limit}${search?`&search=${search}`:''}`;
    const res = await axios.get(url, { headers });
    if (res.data && res.data.status) {
      dispatch({ type: GET_STAFF_LISTING, payload:{...res.data,uid:uid}});
      return res;
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    console.error("Error:", error);
    check_token_expired_logout(error)
  }
};
export const get_staff = async (token, dispatch, id) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  };
  try {
    const url = `${REACT_APP_BASE_URL}admin/get-staff/${id}`;
    const res = await axios.get(url, { headers });
    if (res.data && res.data.status) {
      dispatch({ type: GET_SINGLE_STAFF, payload: res.data.data});
      return res;
    } else {
      console.log("You have an error");
    }
  } catch (error) {
    console.error("Error:", error);
    check_token_expired_logout(error)
  }
};
export const manage_staff = async (token,data,id,dispatch) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token,
  };
  try {
    const url = `${REACT_APP_BASE_URL}admin/${id?`editUser?userId=${id}`:'create-staff'}`;
    const res = id?await axios.put(url,data,{ headers }):await axios.post(url,data,{ headers });
    if (res.data && res.data.status) {
      toast.success(res.data.message);
      get_all_staff(token, dispatch, 1, 10, null)
      return true;
    } else {
      toast.error(res.data.error, { toastId: "errorsearchsaved", autoClose: 1000 });
      return false;
    }
  } catch (error) {
    console.error("Error:", error);
    toast.error(error.response.data.error || error.response.data.message, { toastId: "errorsearchsaved", autoClose: 1000 });
    check_token_expired_logout(error)
  }
};
export const user_dispute = async (dispatch,token,page,limit,search,method,data) => {
  try {
      const url = `${REACT_APP_BASE_URL}${(method==='get')?`admin/dispute-list?page=${page}&limit=${limit}${search?`&search=${search}`:''}`:'admin/change-status'}`;
      const HEADERS = {
          headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
          }
      };
      const res = (method==='get')?await axios.get(url, HEADERS)
      :await axios.put(url, JSON.stringify(data), HEADERS);
      if (res.data && res.data.status) {
          if(method=='get'){
              dispatch({ type: USER_DISPUTES, payload: res.data });
          }else{
              toast.success(res.data.message, { toastId: "savedsearchshs", autoClose: 1000 });
              return true;
          }
      }
  } catch (error) {
      toast.error(error.response.data.error, { toastId: "errorsearchsaved", autoClose: 1000 });
      check_token_expired_logout(error);
  }
};
export const user_flag_comments = async (dispatch,token,page,limit,search,method,data) => {
  try {
      const url = `${REACT_APP_BASE_URL}${(method==='get')?`item/flag-Comments?page=${page}&limit=${limit}${search?`&search=${search}`:''}`:'item/flag-Comments-status'}`;
      const HEADERS = {
          headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
          }
      };
      const res = (method==='get')?await axios.get(url, HEADERS)
      :await axios.post(url, JSON.stringify(data), HEADERS);
      if (res.data && res.data.status) {
          if(method=='get'){
              dispatch({ type: USER_FLAG_COMMENTS, payload: res.data });
          }else{
              toast.success("Succussfully updated flag comment status!", { toastId: "savedsearchshs", autoClose: 1000 });
              return true;
          }
      }
  } catch (error) {
      toast.error(error.response.data.error, { toastId: "errorsearchsaved", autoClose: 1000 });
      check_token_expired_logout(error);
      return false;
  }
};
