import { FormControl } from "@mui/joy";
import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button, Form, FormLabel } from "react-bootstrap";
import { upload_sell_item_photo } from "../../reduxData/user/userAction";
import { Link } from "react-router-dom";
import Buying from "./Buying";
import Selling from "./Selling";
import Finalizing from "./Finalizing";
import SellingContent from "./HowItWorking/SellingContent";
import BuyingContent from "./HowItWorking/BuyingContent";
import FinalizingContent from "./HowItWorking/FinalizingContent";
import { useSelector } from "react-redux";

const { REACT_APP_BASE_URL } = process.env;

const HowItWorks = () => {
    const [currentTab, setCurrentTab] = useState("buying");
    const currentPage = JSON.parse(localStorage.getItem("currentTabb"));
    useEffect(() => {
        if (currentPage) {
            setCurrentTab(currentPage);
        } else {
            setCurrentTab("buying");
        }
    }, []);

    return (
        <div className="mt-2">
            <div className="container">
                <div className="d-flex justify-content-between">
                    <h3 className="mb-2 fw-600">How It Works</h3>
                    <div className="col-lg-8 col-md-8 pt-2">
                        <div className="text-md-end">
                            <ul className="list-inline mb-0 sorting-list">
                                <li className="list-inline-item mb-2 mb-md-0">
                                    <Link
                                        className={`text-decoration-none border rounded-pill px-3 py-2 ${currentTab === 'buying' ? 'active' : ''}`}
                                        onClick={() => { setCurrentTab("buying"); localStorage.setItem("currentTabb", JSON.stringify("buying")); }}
                                    >
                                        Buying a collectible
                                    </Link>
                                </li>
                                <li className="list-inline-item mb-2 mb-md-0">
                                    <Link
                                        className={`text-decoration-none border rounded-pill px-3 py-2 ${currentTab === 'selling' ? 'active' : ''}`}
                                        onClick={() => { setCurrentTab("selling"); localStorage.setItem("currentTabb", JSON.stringify("selling")); }}
                                    >
                                        Selling a collectible
                                    </Link>
                                </li>
                                <li className="list-inline-item mb-2 mb-md-0">
                                    <Link
                                        className={`text-decoration-none border rounded-pill px-3 py-2 ${currentTab === 'finalizing' ? 'active' : ''}`}
                                        onClick={() => { setCurrentTab("finalizing"); localStorage.setItem("currentTabb", JSON.stringify("finalizing")); }}
                                    >
                                        Finalizing the sale
                                    </Link>
                                </li>
                            </ul>
                        </div>{" "}
                    </div>
                </div>

                {(currentTab == 'buying') ? <BuyingContent />
                    : (currentTab == 'selling') ? <SellingContent />
                        : (currentTab == 'finalizing') ? <FinalizingContent />
                            : 'No Data Found'
                }

            </div>
        </div>
    )
};

export default HowItWorks;
