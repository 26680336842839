import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import Stack from "@mui/joy/Stack";
import {
  add_category,
  apply_feature_category,
  delete_category,
  edit_category,
  get_category,
} from "../reduxData/user/userAction";
import { Avatar, Box, Table } from "@mui/joy";
import EmptyList from "../Shared/EmptyList";
import { connect, useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const Category = ({ categoriesList, totalCategories }) => {
  const [open, setOpen] = React.useState(false);
  const {user}=useSelector(state=>state.auth);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteCategoryID, setDeleteCategoryID] = useState(null)
  const [categoryName, setCategoryName] = useState(null);
  const [categoryImage, setCategoryImage] = useState(null);
  const [categoryList, setCategoryList] = useState(null);
  const [editId, setEditId] = useState(null);
  const [editObj, setEditObj] = useState(null)
  const { REACT_APP_BASE_URL } = process.env;
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [errors, setErrors] = useState({
    name: '',
    image: ''
  })
  const dispatch = useDispatch();
  const imageRef = useRef();
  const [imagePreview, setImagePreview] = useState(null);
  useEffect(() => {
    fetchData();
  }, [currentPage, perPage]);

  const fetchData = async () => {
    try {
      const data = await get_category(dispatch, localStorage.getItem('token'), currentPage, perPage);
      setCategoryList(data?.data?.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const addNewCategory = async () => {
    try {
      const formData = new FormData();
      formData.append("name", categoryName);
      formData.append("image", categoryImage);
      const data = await add_category(formData, localStorage.getItem("token"));
      if (data?.data?.status) {
        fetchData();
        setOpen(false);
      }
    } catch (error) {
      console.error("Error adding new category:", error);
    }
  };



  const editCategory = async () => {
    const formData = new FormData();
    if (editObj?.category_image !== categoryImage) {
      formData.append("image", categoryImage);
    }
    formData.append("name", categoryName);
    try {
      const data = await edit_category(formData, localStorage.getItem('token'), editObj?._id)
      if (data?.data?.status) {
        fetchData()
        setOpen(false);
        setImagePreview("");
      }
    } catch (error) {
      console.error("Error editing category:", error)
    }
  }

  const deleteCategory = async (id) => {
    try {
      const data = await delete_category(id, localStorage.getItem('token'));
      if (data?.data?.status) {
        fetchData();
        setDeleteModal(false)
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const setEditCategory = (edit) => {
    setCategoryName(edit?.name);
    setCategoryImage(`${REACT_APP_BASE_URL}${edit?.category_image}`);
    setEditId(edit?._id);
    setEditObj(edit)
    setOpen(true);
  };

  const handleImage = (e) => {
    e.preventDefault();
    imageRef.current.click();
  };
  const handleChange = (e, label) => {
    if (label == 'name') {
      if (!e.target.value) {
        setErrors({ ...errors, [label]: "Name is required" })
      } else {
        setErrors({ ...errors, [label]: "" })
      }
    }
    if (label == 'image') {
      setCategoryImage(e.target.files[0]);
      setImagePreview(URL.createObjectURL(e.target.files[0]));
      setErrors({ ...errors, [label]: "" })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({
      name: !categoryName ? 'Name is required' : null,
      image: !categoryImage ? 'Image is required' : null,
    });
    const formData = new FormData();
    if (!categoryName || !categoryImage) {
      return;
    }

    if (editId) {
      formData.append("name", categoryName);
      formData.append("image", categoryImage);
      const data = await edit_category(formData, localStorage.getItem('token'), editObj?._id)
      if (data?.data?.status) {
        fetchData()
        setOpen(false);
        setImagePreview("");
      }
    } else if (!editId) {
      formData.append("name", categoryName);
      formData.append("image", categoryImage);
      const data = await add_category(formData, localStorage.getItem("token"));
      if (data?.data?.status) {
        fetchData();
        setOpen(false);
        setImagePreview("");
      }
    }
  };

  const handleFeatured = async (item) => {
    const categorydata = {
      name: item?.name.trim(),
      image: item?.category_image
    }
    if (item?.is_feature === false) {
      categorydata.is_feature = true;
    } else if (item?.is_feature === true) {
      categorydata.is_feature = false;
    }
    await apply_feature_category(categorydata, localStorage.getItem('token'), item?._id);
    await get_category(dispatch, localStorage.getItem('token'), 1, 10);
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between">
        <h3 className="fw-600">Auctions Category</h3>
        {(((user?.role==='staff') && (user?.permission?.p_categories>1)) || (user?.role!=='staff')) &&  <button
          className="btn common-button"
          onClick={() => {
            setEditId(null);
            setCategoryName(null);
            setCategoryImage(null);
            setOpen(true);
          }}
        >
          New category
        </button>}
      </div>
      <Modal open={open} onClose={() => { setOpen(false); setErrors({ name: '', image: '' }); setImagePreview(null); }}>
        <ModalDialog>
          <DialogTitle>{editId ? 'Edit' : 'Create new'} category</DialogTitle>
          <DialogContent>
            Fill in the information of the Category.
          </DialogContent>
          <form onSubmit={(e) => { handleSubmit(e); }}>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Category Name</FormLabel>
                <Input
                  autoFocus
                  placeholder="Enter name"
                  value={categoryName}
                  onChange={(e) => { setCategoryName(e.target.value.trim()); handleChange(e, 'name') }}
                />
                {errors?.name && <div className="error_msg">{errors?.name}</div>}
              </FormControl>
              {/* console.log (errors) */}
              <FormControl>
                <FormLabel>Category Image</FormLabel>
                <div className="d-flex">
                  {imagePreview ?
                    <div className="mt-3"><img src={imagePreview} alt="category" className="preview-image" /></div>
                    :
                    !imagePreview && categoryImage ?
                      <div className="mt-3"><img src={categoryImage} alt="category" className="preview-image" /></div>
                      :
                      <div className="mt-3"></div>
                  }
                  {/* {categoryImage && <div className="mt-3"><img src={categoryImage} alt="category" className="preview-image" /></div>} */}
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => { handleChange(e, 'image') }}
                    className="align-items-center d-none"
                    ref={imageRef}
                  />
                  <button type="button" onClick={handleImage} className="btn btn-warning ms-4 mt-3" style={{ height: '40px' }}>
                    {editId ? 'Edit' : 'Upload'} Image
                  </button>
                </div>
                {errors?.image && <div className="error_msg">{errors?.image}</div>}

              </FormControl>
              <button type="submit" className="btn common-button">Submit</button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>

      <Modal open={deleteModal} onClose={() => setDeleteModal(false)}>
        <ModalDialog>
          <DialogTitle>Delete Category</DialogTitle>
          <DialogContent>
            Are you sure you want to delete category.
          </DialogContent>
          <Box>
            <Button onClick={() => setDeleteModal(false)}>Cancel</Button>
            <Button className="mx-2" color="danger" onClick={() => deleteCategory(deleteCategoryID)}>Delete</Button>
          </Box>

        </ModalDialog>
      </Modal>


      <div className="table-responsive mt-3">
        <table className="table table-hover user-management-table auction-category-list">
          <thead className="border-gray">
            <th>Thumbnail</th>
            <th>Category Name</th>
            <th>Featured</th>
            {(((user?.role==='staff') && (user?.permission?.p_categories>1)) || (user?.role!=='staff')) && <th>Action</th>}
          </thead>
          <tbody>
            {totalCategories > 0 ?
              categoriesList.map((item, index) => (
                <tr key={index}>
                  <td>
                    <Avatar
                      src={`${REACT_APP_BASE_URL}${item.category_image}`}
                      alt={`Item Thumbnail ${index + 1}`}
                    />
                  </td>
                  <td>{item.name}</td>
                  <td>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" disabled={((user?.role==='staff') && (user?.permission?.p_categories<2))} role="switch" id="flexSwitchCheckDefault" checked={item?.is_feature} onChange={() => handleFeatured(item)} />
                    </div>
                  </td>
                  {(((user?.role==='staff') && (user?.permission?.p_categories>1)) || (user?.role!=='staff')) && <td>
                    <i
                      className="cursor-pointer mx-2 far fa-edit edit"
                      onClick={() => setEditCategory(item)}
                    />


                  {(((user?.role==='staff') && (user?.permission?.p_categories>2)) || (user?.role!=='staff')) && <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      className="cursor-pointer delete-icon bi bi-trash3-fill"
                      onClick={() => { setDeleteModal(true); setDeleteCategoryID(item?._id) }}
                    >
                      <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                    </svg>}
                  </td>}
                </tr>
              )) :
              <EmptyList name="Category" />
            }
          </tbody>
        </table>
        {/* <div className="mt-3">
          <button
            className="btn btn-sm me-2"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            Previous
          </button>
          <span>Page {currentPage}</span>
          <button
            className="btn btn-sm ms-2"
            disabled={!categoryList || categoryList.length < perPage}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
          </button>
        </div> */}
      </div>

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    categoriesList: state.user.categoriesList,
    totalCategories: state.user.totalCategories
  }
}
export default connect(mapStateToProps)(Category);
