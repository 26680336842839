import React, { useEffect, useState } from "react";
import {
    Box,
    Input,
    Option,
    Select
} from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EmptyData from "../components/EmptyData";
import CustomPagination from "../components/CustomPagination";
import { user_flag_comments } from "../reduxData/user/userAction";
import DefaultImg from '../images/no_profile.png';
import { VIEW_ITEM_DETAIL } from "../reduxData/user/userTypes";
import ViewDetail from "../Modals/ViewDetail";
import { doc, getDoc, updateDoc } from "@firebase/firestore";
import { db } from "../firebase";
const { REACT_APP_BASE_URL } = process.env;
const FlagCommentsList = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);
    const { flags, flagsTotal } = useSelector(state => state.user);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(null);
    const [isPop, setIsPop] = useState(false);
    const allStatus = [
        { label: 'Pending', value: 0 },
        { label: 'Reviewed', value: 1 },
        { label: 'Removed', value: 2 },
        { label: 'Ignored', value: 3 }
    ];
    useEffect(() => {
        user_flag_comments(dispatch, localStorage.getItem('token'), page, pageSize, search, 'get', null);
    }, [user, dispatch, page, pageSize, search]);
    const statusChange = async (item, value) => {
        const res = await user_flag_comments(dispatch, localStorage.getItem('token'), page, pageSize, search, 'put', { id: item?._id, status: value });
        if (res) {
            try {
                const replyRef = (item?.comment_id.length === 3) ? doc(
                    db,
                    "comments",
                    item?.itemDetails?._id,
                    "comments",
                    item?.comment_id[0],
                    "replies",
                    item?.comment_id[1],
                    "replyOfReplies",
                    item?.comment_id[2]
                ) : (item?.comment_id.length === 2) ? doc(
                    db,
                    "comments",
                    item?.itemDetails?._id,
                    "comments",
                    item?.comment_id[0],
                    "replies",
                    item?.comment_id[1]
                ) : doc(
                    db,
                    "comments",
                    item?.itemDetails?._id,
                    "comments",
                    item?.comment_id[0]
                );
                const docSnap = await getDoc(replyRef);
                if (docSnap.exists()) {
                    let comment = docSnap.data();
                    comment.flagStatus = value;
                    await updateDoc(replyRef, comment);
                }
            } catch (error) {
                console.error("Error adding reply:", error);
                throw error;
            }
        }
    }
    const CommentComponent = ({ comment }) => {
        const taggedUsers = comment?.taggedUser?.split(",").map((user) => user.trim());
        const regex = new RegExp(`@(${taggedUsers?.join("|")})`, "gi");
        const highlightedComment = comment?.comment?.split(regex)?.map((part, index) =>
            taggedUsers?.includes(part.trim()) ? (
                <span key={index} style={{ color: "gold", fontWeight: "bold" }}>
                    @{part}
                </span>
            ) : (
                part
            )
        );
        return <p className="comment-text mb-0">{highlightedComment}</p>;
    };
    const GetComment = ({ comment }) => {
        return <>
            {(comment?.userId === comment?.sellerId) ? <span className="btn btn-green text-white py-0 px-3 ms-2">
                Seller
            </span>
                : ((comment?.isCard === true) ? <i class="fa fa-check-circle text-warning ms-2"></i>
                    : ''
                )}
        </>
    }
    return (<div>
        <div className="container">
            <Box className="d-flex mb-4 justify-content-between align-items-center">
                <h3 className="mb-0 fw-600">{`Flag Comments (${flagsTotal})`}</h3>
                <div className="d-flex justify-content-end align-items-center">
                    <Input
                        className="form-control"
                        id="nameInput"
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </Box>
            <div className="table-responsive">
                <table className="table user-management-table">
                    <thead className="border-gray">
                        <tr>
                            <th>User</th>
                            <th>Item</th>
                            <th>Comment</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(flagsTotal > 0) ?
                            flags.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="d-flex align-items-center justify-content-left cursor-pointer" onClick={() => navigate(`/editUser/${item?.userDetails?._id}`)}>
                                            <span className="flag-comments-img-wrap">
                                                <img
                                                    src={item?.userDetails?.profile_pic ? `${REACT_APP_BASE_URL}${item?.userDetails?.profile_pic}` : DefaultImg}
                                                    style={{ width: 49, height: 49, borderRadius: 75 }} className="object-fit-cover "
                                                    alt=""
                                                />
                                            </span>
                                            <div className="px-2">
                                                <span className="text-capitalize">
                                                    {item?.userDetails?.user_name}
                                                </span>
                                                <br />
                                                {item?.userDetails?.email}
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        className="text-capitalize"
                                        onClick={() => {
                                            setIsPop(true);
                                            dispatch({ type: VIEW_ITEM_DETAIL, payload: item?.itemDetails });
                                        }}
                                    >
                                        <div className="d-flex align-items-center justify-content-left cursor-pointer">
                                            <span className="flag-comments-img-wrap">
                                                {item?.itemDetails?.photos?.length > 0 ?
                                                    <img src={`${REACT_APP_BASE_URL}${item?.itemDetails?.photos[0]}`} height={50} width={65} alt='not found' className="object-fit-cover rounded" />
                                                    : <img alt='not found' />}
                                            </span>
                                            <div className="px-2">
                                                {item?.itemDetails?.item_name}<br />
                                                <b>Owner: </b>
                                                {item?.itemDetails?.owner?.full_name}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-capitalize">
                                        <b><i className="fa fa-user"></i>{" "}{item?.comment?.name}</b>
                                        <GetComment comment={item?.comment} />
                                        <br />
                                        <CommentComponent comment={item?.comment} />
                                    </td>
                                    <td className="text-capitalize">
                                        <Select defaultValue={item?.status} disabled={!(((user?.role === 'staff') && (user?.permission?.p_flags > 0)) || (user?.role !== 'staff'))}>
                                            <Option value={null} disabled>Select Status</Option>
                                            {allStatus.map((option) => (
                                                <Option key={option.value} value={option.value} onClick={() => statusChange(item, option.value)}>{option.label}</Option>
                                            ))}
                                        </Select>
                                    </td>
                                </tr>
                            ))
                            : <EmptyData />
                        }
                    </tbody>
                </table>
            </div>
            <div className="mt-3">
                {flagsTotal > pageSize && (
                    <CustomPagination
                        total={flagsTotal}
                        onPageChange={(page, perPage) => { setPage(page); setPageSize(perPage); }}
                    />
                )}
            </div>
        </div>
        <ViewDetail show={isPop} handleClose={() => { setIsPop(false); dispatch({ type: VIEW_ITEM_DETAIL, payload: null }); }} />
    </div>
    );
};

export default FlagCommentsList;